import { PropertyType } from '../../types';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { styles } from './presentationStyles';
import logoBeige from '../../assets/images/logo-beige.png';
import logoWhite from '../../assets/images/logo-white.png';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';
import { Footer, Header } from './Common';
import { usePropertyPageContext } from '../contexts/PropertyPageContext';
import { Loader } from '../shared/Loader';

Font.register({
  family: 'Manrope',
  src: manropeFont,
});
Font.register({
  family: 'Cormorant700',
  src: cormorantFont,
});

interface FirstPageProps {
  property: PropertyType;
  mainImageUrl: string;
}

export const FirstPage = ({ property, mainImageUrl }: FirstPageProps) => {
  const advantages = property.advantages.slice(0, 7);
  return (
    <Page size={{ width: 595, height: 842 }} style={styles.wrapper}>
      <Header />
      <View style={styles.content}>
        {/* Top section with image and info box */}
        <View style={styles.topSection}>
          <Image style={styles.mainBcImg} src={mainImageUrl} />
          <View style={styles.bcInfo}>
            <Text style={styles.name}>{property?.name}</Text>
            <Text style={styles.address}>{property?.class}</Text>
            <Text style={styles.address}>{property?.certificate}</Text>
            <Text style={styles.address}>{property?.address}</Text>
            <View style={styles.square}>
              <Text>{property.gba_m2} sq.m</Text>
              <Text>total available area</Text>
            </View>
          </View>
        </View>

        {/* Middle section */}
        <View style={styles.middleSection}>
          {/* Description */}
          <View style={styles.textSection}>
            <Text style={styles.title}>Опис</Text>
            <Text style={styles.text}>{property.shortDesc}</Text>
          </View>
          {/* Characteristics */}
          <View style={styles.characteristicsSection}>
            <Text style={styles.title}>Переваги</Text>
            {advantages.map((adv, index) => (
              <Text key={index} style={styles.listItem}>• {adv}</Text>
            ))}
          </View>
        </View>
      </View>
      <Footer />
    </Page>
  );
};
