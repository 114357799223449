import { useFormikContext } from 'formik';
import { InputField } from '../../shared/formFields/InputField';
import { SelectField } from '../../shared/formFields/SelectField';
import { Checkbox } from '../../shared/formFields/Checkbox';
import { PropertyType } from '../../../types';
import { FieldsGroupWrapper, SelectWrapper } from './PropertyFormStyle';
import { fireprotectOptions, heatingOptions, liftsOptions, ventilationOptions } from './options';
import { StyledLabel } from '../../shared/formFields/FormFieldStyles';

const currencyOptions = [
  { label: 'грн.', value: 'UAH' },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
];

export const CharacteristicsFields = () => {
  const { values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext<PropertyType>();

  const handleChangeSecurity = (index: number) => {
    const updatedSecurity = values.security?.map((item, currentIndex) =>
      currentIndex === index ? { ...item, value: !item.value } : item
    );
    setFieldValue('security', updatedSecurity);
  };
  const handleChangeInternet = (index: number) => {
    const updatedInternet = values.internet?.map((item, currentIndex) =>
      currentIndex === index ? { ...item, value: !item.value } : item
    );
    setFieldValue('internet', updatedInternet);
  };

  return (
    <>
      <FieldsGroupWrapper>
        <InputField
          label='Площа будівлі GBA'
          name='gba_m2'
          value={values.gba_m2 || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
        <InputField
          label='Площа будівлі GLA'
          name='gla_m2'
          value={values.gla_m2 || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
        <InputField
          label='Висота стелі. м'
          name='height'
          value={values.height || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
        <InputField
          label='Площа типового поверху. кв.м'
          name='floorSquare'
          value={values.floorSquare || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
      </FieldsGroupWrapper>

      <FieldsGroupWrapper>
        <InputField
          label='Орендна ставка'
          name='rent'
          value={values.rent || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width={'150px'}
        />
        <SelectField
          name='currency'
          label='Виберіть валюту'
          value={values.currency}
          options={currencyOptions}
          handleChange={setFieldValue} //
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
          errors={errors}
          $width='100px'
        />
        <InputField
          label='ОРЕХ'
          name='opex'
          value={values.opex || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'150px'}
        />
        <SelectField
          name='currencyOpex'
          label='Виберіть валюту'
          value={values.currency}
          options={currencyOptions}
          handleChange={setFieldValue} //
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
          errors={errors}
          $width='100px'
        />
        <InputField
          label='R/U по будівлі, %'
          name='ruratio'
          value={values.ruratio}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width={'150px'}
        />
        <InputField
          label='Ціна продажу'
          name='sellingPrice'
          value={values.sellingPrice || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width={'150px'}
        />
        <SelectField
          name='sellingCurrency'
          label='Виберіть валюту'
          value={values.sellingCurrency}
          options={currencyOptions}
          handleChange={setFieldValue} //
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
          errors={errors}
          $width='100px'
        />
      </FieldsGroupWrapper>
      <FieldsGroupWrapper>
        <InputField
          label='Енергопостачання, кВт'
          name='energySupply'
          value={values.energySupply}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width={'200px'}
        />
        <Checkbox
          label={'Укриття'}
          name={'shelter'}
          handleChange={(isChecked) => setFieldValue('shelter', isChecked)}
          checked={!!values.shelter}
        />
        <Checkbox
          label={'Резервне живлення'}
          name={'generator'}
          handleChange={(isChecked) => setFieldValue('generator', isChecked)}
          checked={!!values.generator}
        />
      </FieldsGroupWrapper>
      <FieldsGroupWrapper>
        <SelectWrapper>
          <SelectField
            name='heating'
            label='Виберіть тип опалення'
            value={values.heating}
            options={heatingOptions}
            handleChange={setFieldValue}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            touched={touched}
            errors={errors}
            //$width='200px'
          />
        </SelectWrapper>

        <SelectWrapper>
          <SelectField
            name='ventilation'
            label='Система вентиляції і кондиціонування'
            value={values.ventilation}
            options={ventilationOptions}
            handleChange={setFieldValue}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            touched={touched}
            errors={errors}
            //$width='400px'
          />
        </SelectWrapper>

        <SelectWrapper>
          <SelectField
            name='lifts'
            label='Ліфтова система'
            value={values.lifts}
            options={liftsOptions}
            handleChange={setFieldValue}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            touched={touched}
            errors={errors}
            //$width='200px'
          />
        </SelectWrapper>
      </FieldsGroupWrapper>
      <FieldsGroupWrapper>
        <SelectWrapper>
          <SelectField
            name='fireprotect'
            label='Протипожежна система'
            value={values.fireprotect}
            options={fireprotectOptions}
            handleChange={setFieldValue}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            touched={touched}
            errors={errors}
            //$width='200px'
          />
        </SelectWrapper>

        <Checkbox
          label={'Пожежна сигналізація'}
          name={'fireAlarm'}
          handleChange={(isChecked) => setFieldValue('fireAlarm', isChecked)}
          checked={!!values.fireAlarm}
        />
        <Checkbox
          label={'Детектори диму'}
          name={'smokeDetectors'}
          handleChange={(isChecked) => setFieldValue('smokeDetectors', isChecked)}
          checked={!!values.smokeDetectors}
        />
      </FieldsGroupWrapper>
      <FieldsGroupWrapper>
        <StyledLabel>Система безпеки</StyledLabel>
        {Array.isArray(values.security) &&
          values.security?.map(({ name, value }, index) => {
            return (
              <Checkbox
                key={index}
                label={name}
                name={'security'}
                handleChange={() => handleChangeSecurity(index)}
                checked={value}
              />
            );
          })}
        {/* <InputField
          label='Система безпеки'
          name='security'
          value={values.security}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        /> */}
      </FieldsGroupWrapper>
      <FieldsGroupWrapper>
        <InputField
          label='Парковка для автомобілів'
          name='parking'
          value={values.parking}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width='30%'
        />
        <InputField
          label='Кількість поверхів'
          name='floors'
          value={values.floors}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width='30%'
        />
        <InputField
          label='Доступ до БЦ'
          name='access'
          value={values.access}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width='30%'
        />
      </FieldsGroupWrapper>
      <FieldsGroupWrapper>
        <StyledLabel>Інтернет</StyledLabel>
        {Array.isArray(values.internet) &&
          values.internet?.map(({ name, value }, index) => {
            return (
              <Checkbox
                key={index}
                label={name}
                name={'internet'}
                handleChange={() => handleChangeInternet(index)}
                checked={value}
              />
            );
          })}
      </FieldsGroupWrapper>
    </>
  );
};
