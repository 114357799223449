import { Text, View, Image, Font } from '@react-pdf/renderer';
import { styles } from './presentationStyles';
import logoBeige from '../../assets/images/logo-beige.png'
import logoWhite from '../../assets/images/logo-white.png';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';

Font.register({
    family: 'Manrope',
    src: manropeFont,
  });
  Font.register({
    family: 'Cormorant700',
    src: cormorantFont,
  });

interface HeaderProps {
    title?: string;
    address?: string;
}
export const Header = ({title, address}:HeaderProps) => (
    <View style={styles.header}>
      <View style={styles.logo}>
        <Image style={styles.logoImg} src={logoBeige} />
      </View>
      <View style={styles.text}>
        <Text>Phone: +123456789009</Text>
        <Text>Website: ipi.market</Text>
      </View>
    </View>
);

export const Footer = () => (
    <View style={styles.footer}>
    <View style={styles.logo}>
      <Image style={styles.logoImg} src={logoWhite} />
    </View>
    <View style={styles.text}>
      <Text>Phone: +123456789009</Text>
      <Text>Website: www.ipi.com</Text>
    </View>
  </View>
);