import { useParams } from 'react-router-dom';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';
import { useImagesByPropertyId, usePropertyById } from '../../services/propertiesService';
import { Loader } from '../shared/Loader';
import { styles } from './presentationStyles';
import { FirstPage } from './FirstPage';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';
import { SecondPage } from './SecondPage';
import { usePropertyPageContext } from '../contexts/PropertyPageContext';
import { constants } from '../../utils/constants';
import { useApiKey } from '../../services/googleMapsApi';

Font.register({
  family: 'Manrope',
  src: manropeFont,
});
Font.register({
  family: 'Cormorant700',
  src: cormorantFont,
});

export const PresentationPage = () => {
  const { property, isPropertyLoading, images, isImgesLoading } = usePropertyPageContext();
  const mainImage = images?.find((img: string) => img.includes('main'));
  const mainImageUrl = `${constants.IMAGES_PUBLIC_BASE_URL}${mainImage}`;
  const restImages = images?.filter((img: string) => !img.includes('main'));
  const secondImageUrl = !!restImages ? `${constants.IMAGES_PUBLIC_BASE_URL}${restImages[0]}` : '';
  const { data } = useApiKey();
  const apiKey = data?.data.api_key ?? null;


  if (!property || !images || isPropertyLoading || isImgesLoading) return <Loader />;

  return (
    <>
      <div>debug mode</div>
      <PDFViewer style={styles.viewer}>
        <Document>
          <FirstPage property={property} mainImageUrl={mainImageUrl} />
          <SecondPage property={property} imageUrl={secondImageUrl} apiKey={apiKey}/>
        </Document>
      </PDFViewer>
    </>
  );
};
