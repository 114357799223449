import { useParams } from 'react-router-dom';
import { PropertyPageWrapper, StyledLink, PageHeader, Icon, StyledLinkButton } from './PropertyPageStyle';
import { OfficesTable } from './OfficesTable';
import arrow_back from '../../../assets/icons/arrow_back.svg';
import { usePropertyPageContext } from '../../contexts/PropertyPageContext';

export const PropertyPage = () => {
  const { propertyId } = useParams();
  const { property, offices, selectedOffices, setSelectedOffices } = usePropertyPageContext();
  return (
    <PropertyPageWrapper>
      <PageHeader>
        <StyledLink to={'/properties'}>
          <Icon src={arrow_back} /> повернутися до списку{' '}
        </StyledLink>
      </PageHeader>
      <h1>{property?.name}</h1>
      <div>{property?.shortDesc}</div>
      <div>{offices && <OfficesTable />}</div>
      <StyledLinkButton to={`/presentation/${propertyId}`} target='_blank' disabled={selectedOffices.length === 0}>
        Завантажити PDF
      </StyledLinkButton>
    </PropertyPageWrapper>
  );
};
