import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  wrapper: {
    margin: '0 auto',
    fontSize: 12,
    fontFamily: 'Manrope',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    height: 55,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
    backgroundColor: '#ffffff',
    color: '#827653',
    paddingLeft: 24,
    paddingRight: 24,
},
  logo: {
    width: 148,
    justifyContent: 'center',
  },
  logoImg: {
    width: 148,
  },
  text: {
    justifyContent: 'center',
    fontSize: 12,
  },
  content: {
    flexGrow: 1, // Ensures the content takes up available space
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  topSection: {
    position: 'relative',
    marginBottom: 10,
    height: 324,
    overflow: 'hidden'
  },
  mainBcImg: {
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  bcInfo: {
    width: 182,
    height: 324,
    backgroundColor: 'rgba(6, 34, 48, 0.82)',
    color: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 13,
    justifyContent: 'center',
    gap: 15,
  },
  name: {
    fontFamily: 'Cormorant700',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'uppercase',
  },
  address: {
    fontSize: 14,
  },
  square: {
    fontFamily: 'Cormorant700',
    fontWeight: 700,
    fontSize: 16,
  },
  middleSection: {
    paddingLeft: 24,
    paddingRight: 24,
    gap: 5,
  },
  textSection: {
    maxHeight: 150,
    overflow: 'hidden',
  },
  characteristicsSection: {
    maxHeight: 200,
    overflow: 'hidden',
  },

  title: {
    color: '#827653',
    fontWeight: 600,
    marginBottom: 5,
  },
  textContent: {
    color: '#000000',
    fontSize: 14,
    lineHeight: 1.5,
  },
  footer: {
    height: 55,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#062230',
    color: '#ffffff',
    padding: '14px 24px',
    position: 'relative',
  },
  listItem: {
    marginBottom: 5
  },
  mapImg: {
    width: 250,
    height: 260
  },

});
