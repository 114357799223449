import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useImagesByPropertyId, useOfficesByPropertyId, usePropertyById } from '../../services/propertiesService';
import { OfficeType, PropertyType } from '../../types';

interface PropertyPageContextProps {
  property: PropertyType | null;
  isPropertyLoading: boolean;
  offices: OfficeType[];
  mutateOffices: () => void;
  isOfficesLoading: boolean;
  selectedOffices: string[];
  setSelectedOffices: React.Dispatch<React.SetStateAction<string[]>>;
  images: string[] | null;
  isImgesLoading: boolean;
}

const PropertyPageContext = createContext<PropertyPageContextProps>({
  property: null,
  isPropertyLoading: false,
  offices: [],
  mutateOffices: () => {},
  isOfficesLoading: false,
  selectedOffices: [],
  setSelectedOffices: () => {},
  images: null,
  isImgesLoading: false,
});

interface PropertyPageContextProviderProps {
  children: ReactNode;
}

export const PropertyPageProvider = ({ children }: PropertyPageContextProviderProps) => {
  const [selectedOffices, setSelectedOffices] = useState<string[]>([]);
  const { propertyId } = useParams();
  const { data: propertyResponse, isLoading: isPropertyLoading } = usePropertyById(propertyId);
  const {
    data: officesResponse,
    mutate: mutateOffices,
    isLoading: isOfficesLoading,
  } = useOfficesByPropertyId(propertyId);
  const { data: imagesResponse, isLoading: isImgesLoading } = useImagesByPropertyId(propertyId);

  const value: PropertyPageContextProps = {
    property: propertyResponse?.data ?? null,
    isPropertyLoading,
    offices: officesResponse?.data ?? [],
    mutateOffices,
    isOfficesLoading,
    selectedOffices,
    setSelectedOffices,
    images: imagesResponse?.data ?? null,
    isImgesLoading,
  };

  return <PropertyPageContext.Provider value={value}>{children}</PropertyPageContext.Provider>;
};

export const usePropertyPageContext = () => useContext(PropertyPageContext);
