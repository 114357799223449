import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const PropertyFormWrapper = styled.div`
  padding: 20px;
`;
export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  &:hover {
    color: black;
  }
`;

export const FieldsWrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 0;
  margin: 15px 0;
`;

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  color: ${(props) => props.theme.blue};
  padding: 5px;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const GroupWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center; //flex-start;
  justify-content: space-between;
`;
export const FieldsGroupWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 0;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 0;
`;

export const SubTitle = styled.div`
  margin: 5px 0;
  font-size: 14px;
  font-weight: 600;
`;

export const RowWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const CheckboxWrapper = styled.div``;
export const SelectWrapper = styled.div`
  flex-basis: 45%;
`;
interface GroupItemsWrapperProps {
  $width: string;
  $gap: string;
}
export const GroupItemsWrapper = styled.div<GroupItemsWrapperProps>`
  display: flex;
  flex-basis: ${(props) => props.$width};
  gap: ${(props) => props.$gap};
`;

export const Tab = styled.div<{ selected: boolean; highlight?: string }>`
  border: none;
  background: ${(props) => (props.selected ? props.theme.white : props.theme.flatWhite)};
  border-top: 5px solid ${(props) => props.highlight};
  color: ${(props) => (props.selected ? props.theme.marineBlue : props.theme.marineBlue50)};
  font-weight: ${(props) => (props.selected ? '600' : 'normal')};
  font-size: 14px;
  white-space: nowrap;
  padding: 8px 24px;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 4px 4px 0 0;
  width: 15%;
  height: 60px;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  margin-right: 6px;
  transition: height 0.1s linear;

  ${(props) =>
    props.selected &&
    css`
      z-index: 2;
      margin-top: 0;
      border-top: 5px solid ${props.highlight};
      border-bottom: none;
      border-right: 2px solid ${props.theme.whiteBlue};
      height: 70px;
      color: ${props.theme.marineBlue};
    `}
`;
//const StyledTab = styled(Tab)``;

export const TabWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100px;
  justify-content: space-between;
`;

export const Error = styled.span`
  color: ${(props) => props.theme.red};
  font-size: 14px;
  position: absolute;
  display: block;
  max-width: 200px;
`;
export const ErrorListWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  color: ${(props) => props.theme.marineBlue};
  & ol {
    font-size: 12px;
    margin: 10px 20px;
    padding: 0;
    & li {
      padding: 5px;
    }
  }
`;

export const ImageItemWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const GridWrapper = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 2fr max-content;
`;

export const DropZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border: 1px dotted gray;
  border-radius: 20px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageUploaderWrapper = styled.div`
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
