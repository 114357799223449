import { ModalLayout } from '../../../shared/ModalLayout';
import { createRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, ButtonWrapper, DropZone, Icon } from '../PropertyFormStyle';
import doneButtonGreen from '../../../../assets/icons/doneButtonGreen.svg';
import closeButtonRed from '../../../../assets/icons/closeButtonRed.svg';

const modalOptions = {
  size: 'medium',
  title: 'Crop Image',
};

interface ModalcropperProps {
  handleCloseModal: () => void;
  image: string;
  setCropData: React.Dispatch<React.SetStateAction<string | null>>;
}
export const ModalCropper = ({ handleCloseModal, image, setCropData }: ModalcropperProps) => {
  const cropperRef = createRef<ReactCropperElement>();

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      handleCloseModal();
    }
  };

  const onCropperReady = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      // Set the crop box to 100% of the image size
      cropper.setCropBoxData({
        width: cropper.getCanvasData().width,
        height: cropper.getCanvasData().height,
        left: 0,
        top: 0,
      });
    }
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Cropper
        ref={cropperRef}
        style={{ width: '100%' }}
        initialAspectRatio={16 / 9}
        aspectRatio={16 / 9}
        src={image}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        guides={true}
        ready={onCropperReady} // Add the ready event handler
        scalable={false}
        zoomable={false}
        zoomOnWheel={false}
      />
      <ButtonWrapper>
        <Button type='button' onClick={getCropData}>
          <Icon src={doneButtonGreen} width={'20px'} />
          Crop Image
        </Button>
        <Button type='button' onClick={handleCloseModal}>
          <Icon src={closeButtonRed} width={'20px'} />
          Cancel
        </Button>
      </ButtonWrapper>
    </ModalLayout>
  );
};
