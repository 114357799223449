import { useState, useEffect, useRef } from 'react';
import { Icon } from '../PropertyFormStyle';
import { BoxButton } from '../../../shared/buttons/BoxButton';
import addButtonGreen from '../../../../assets/icons/addButtonGreen.svg';
import { ModalCropper } from './ModalCropper';
import { ImagesList } from './imagesList';
import { StyledLabel } from '../../../shared/formFields/FormFieldStyles';

interface ImageType {
  dataURL?: string;
  file?: File;
  [key: string]: any;
}
type ImageListType = Array<ImageType>;

interface Props {
  uploadedImage: ImageListType;
  setUploadedImage: React.Dispatch<React.SetStateAction<ImageListType>>;
  handleUploadImage: () => void;
  buttonTitle: string;
}

export const SingleImageUploaderWithCropper = ({
  uploadedImage,
  setUploadedImage,
  handleUploadImage,
  buttonTitle,
}: Props) => {
  const [isModalCropperVisible, setIsModalCropperVisible] = useState(false);

  const [image, setImage] = useState<string | null>(null);
  const [cropData, setCropData] = useState<string | null>(null);
  const [fileData, setFileData] = useState<File | undefined>();

  const fileInputRef = useRef<HTMLInputElement>(null); // Create a ref for the file input

  useEffect(() => {
    cropData && setUploadedImage([{ dataURL: cropData, file: fileData }]);
  }, [cropData]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    let files: FileList | null = null;

    if ('dataTransfer' in e && e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = (e.target as HTMLInputElement).files;
    }

    if (files && files[0]) {
      const file = files[0];
      setFileData(file);
      //const fileName = file.name;
      //const fileExtension = fileName.split('.').pop(); // Extract the file extension
      //console.log('File extension:', fileExtension);

      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setImage(reader.result as string); // Ensure the result is a string
          setIsModalCropperVisible(true);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileDialog = () => {
    fileInputRef.current?.click(); // Programmatically open the file input dialog
  };

  return (
    <div>
      <input type='file' onChange={onChange} style={{ display: 'none' }} id='file-upload' ref={fileInputRef} />
      <StyledLabel htmlFor='file-upload' style={{ display: 'flex' }}>
        <Icon src={addButtonGreen} width={'20px'} />
        {buttonTitle}
      </StyledLabel>
      {isModalCropperVisible && image && (
        <ModalCropper
          setCropData={setCropData}
          image={image}
          handleCloseModal={() => setIsModalCropperVisible(false)}
        />
      )}
      <ImagesList
        imageList={uploadedImage}
        onImageUpdate={openFileDialog}
        onImageRemove={() => setUploadedImage([])}
        imageWidth={'100'}
      />
      {uploadedImage.length > 0 && (
        <>
          <BoxButton ariaLabel='add' onClick={handleUploadImage} $padding='10px 20px' type='button'>
            Зберегти
          </BoxButton>
        </>
      )}
    </div>
  );
};
