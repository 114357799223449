import { Option, CheckboxGroupProps } from '../../../types';

export const classOptions: Option[] = [
  { label: '', value: '' },
  { label: 'Клас А', value: 'Class А' },
  { label: 'Клас B', value: 'Class B' },
  { label: 'Клас C', value: 'Class C' },
  { label: 'Coworking', value: 'Coworking' },
  { label: 'ОСЗ', value: 'OSZ' },
];

export const actionOptions: Option[] = [
  { label: 'Оренда', value: 'rent' },
  { label: 'Продаж', value: 'sell' },
];

export const heatingOptions: Option[] = [
  { label: '', value: '' },
  { label: 'Центральне', value: 'central' },
  { label: 'Автономне', value: 'autonomous' },
];

export const ventilationOptions: Option[] = [
  { label: '', value: '' },
  { label: 'Припливно-витяжна вентиляційна система', value: 'ventilation' },
  { label: 'Локальна система кондиціювання', value: 'conditioning' },
  { label: 'Комбинированная система', value: 'combined' },
];

export const liftsOptions: Option[] = [
  { label: '', value: '' },
  { label: '+', value: 'yes' },
  { label: 'Швидкісна', value: 'speed' },
];

export const fireprotectOptions: Option[] = [
  { label: '', value: '' },
  { label: 'Водяна система пожежогасіння', value: 'water' },
  { label: 'Комбінована система пожежогасіння', value: 'combined' },
];

export const securityOptions: CheckboxGroupProps[] = [
  { name: 'Цілодобова охорона', value: false },
  { name: 'Відеоспостереження', value: false },
  { name: 'Система контролю доступу', value: false },
];

export const internetOptions: CheckboxGroupProps[] = [
  { name: '+', value: false },
  { name: 'Оптико-волоконні мережі', value: false },
  { name: 'Старлінк', value: false },
];

export const actionTypeOptions: CheckboxGroupProps[] = [
  { name: 'Оренда', value: true },
  { name: 'Продаж', value: true },
];
