import { PropertyType } from '../../types';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { styles } from './presentationStyles';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';
import { Characteristics } from './Characteristics';

Font.register({
  family: 'Manrope',
  src: manropeFont,
});
Font.register({
  family: 'Cormorant700',
  src: cormorantFont,
});

interface FirstPageProps {
  property: PropertyType;
  imageUrl: string;
  apiKey: string;
}

export const SecondPage = ({ property, imageUrl, apiKey }: FirstPageProps) => {
  const mapImgUrl = apiKey
    ? `https://maps.googleapis.com/maps/api/staticmap?center=${property.lat},${property.lng}&zoom=14&size=260x250&markers=color:red|${property.lat},${property.lng}&key=${apiKey}`
    : null;
  return (
    <Page size={{ width: 595, height: 842 }} style={styles.wrapper}>
      <View>
        {mapImgUrl && <Image style={styles.mapImg} src={mapImgUrl} />}
        <Image style={styles.mapImg} src={imageUrl} />
      </View>
      {/* Characteristics */}
      <View style={styles.characteristicsSection}>
        <Text style={styles.title}>Загальна характеристика</Text>
        <Characteristics property={property} />
      </View>
    </Page>
  );
};
