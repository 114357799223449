import * as Yup from 'yup';

export const propertyValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, 'Назва повинна містити принаймні 1 символ')
    .max(50, 'Назва має бути 50 символів або менше')
    .required('Додайте назву'),
  shortDesc: Yup.string().trim(),
  description: Yup.string().trim(),
  class: Yup.string().trim().required('Виберить клас БЦ'),
  address: Yup.string().trim().required('Додайте адресу'),
  district: Yup.string().trim(),
  //lat: Yup.string().trim().required('Додайте широту'),
  //lng: Yup.string().trim().required('Додайте довготу'),
  gba_m2: Yup.number(),
  gla_m2: Yup.number(),
  rent: Yup.string().trim(),
  opex: Yup.string().trim(),
  ruratio: Yup.string().trim(),
  currency: Yup.string().trim(),
  shelter: Yup.boolean(),
  generator: Yup.boolean(),
  parking: Yup.string().trim(),
  lifts: Yup.string().trim(),
  ventilation: Yup.string().trim(),
  energySupply: Yup.string().trim(),
  heating: Yup.string().trim(),
  fireprotect: Yup.string().trim(),
  //security: Yup.string().trim(),
  active: Yup.boolean(),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().trim().required("Додайте ім'я"),
        lastName: Yup.string().trim(),
        phone: Yup.string().trim().required('Додайте номер телефону'),
        email: Yup.string()
          .trim()
          .email('Невірна адреса електронної пошти')
          .required('Додайте електронну адресу'),
      })
    )
    .min(1, 'Додайте як мінімум один контакт'),
  contactsForAdmin: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().trim().required("Додайте ім'я"),
        lastName: Yup.string().trim(),
        phone: Yup.string().trim().required('Додайте номер телефону'),
        email: Yup.string()
          .trim()
          .email('Невірна адреса електронної пошти')
          .required('Додайте електронну адресу'),
      })
    )
    .min(1, 'Додайте як мінімум один контакт для адміністратора'),

  //images: Yup.array().min(1, 'Додайте як мінімум одне зображення'),
});

export const officeValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, 'Назва повинна містити принаймні 1 символ')
    .max(50, 'Назва має бути 50 символів або менше')
    .required('Додайте назву'),
  comment: Yup.string().trim(),
  square: Yup.number(),
  price: Yup.number(),
  currency: Yup.string().trim(),
  state: Yup.string().trim().required('Оберить стан приміщень'),
  planning: Yup.string().trim().required('Оберить планування'),
  //images: Yup.array().min(1, 'Додайте як мінімум одне зображення'),
});
