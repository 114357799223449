import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Home } from './components/home/Home';
import { Navigation } from './components/Navigation';
import { Authentication } from './components/authentication/Authentication';
import { PropertiesList } from './components/properties/PropertiesList';
import { ProtectedRoute } from './components/authentication/ProtectedRoute';
import { PropertyForm } from './components/property/propertyForm/PropertyForm';
import { PropertyPage } from './components/property/propertyPage/PropertyPage';
import { PresentationPage } from './components/presentation/PresentationPage';
import { PropertyPageProvider } from './components/contexts/PropertyPageContext';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Navigation />}>
      <Route path='/auth' element={<Authentication />} />
      <Route element={<ProtectedRoute />}>
          <Route index element={<Home />} />
          <Route path='/properties' element={<PropertiesList />} />
          <Route path='/edit-property/:propertyId?' element={<PropertyForm />} />
          <Route path='/properties/:propertyId' element={<PropertyPageProvider><PropertyPage /></PropertyPageProvider>} />
          <Route path='/presentation/:propertyId' element={<PropertyPageProvider><PresentationPage /></PropertyPageProvider>} />
      </Route>
    </Route>
  )
);
export const App = () => <RouterProvider router={router} />;
